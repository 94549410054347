import { useInstantSearch } from "react-instantsearch";
import { Paragraph } from "../ui/typography";

type Props = {
	children: JSX.Element;
	fallback: React.ReactNode;
};

export function NoResultsBoundary({ children, fallback }: Props) {
	const { results } = useInstantSearch();

	// The `__isArtificial` flag makes sure not to display the No Results message
	// when no hits have been returned.
	if (!results.__isArtificial && results.nbHits === 0) {
		return (
			<>
				{fallback}
				<div hidden>{children}</div>
			</>
		);
	}

	return children;
}

export function NoResults() {
	const { indexUiState } = useInstantSearch();

	return (
		<Paragraph>
			There are no results for <q>{indexUiState.query}</q>.
		</Paragraph>
	);
}
