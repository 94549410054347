import React from "react";
import { useSearchBox, type SearchBoxProps } from "react-instantsearch";
import debounce from "lodash/debounce";
import { spTrackSearchTerm } from "~/utils/tracking";
import { Icon } from "../ui/icons";
import { Input, InputError } from "../ui/form/input";
import { HoneyPotInput } from "../honeypot-input";
// import { sanitizeInput } from "~/utils/misc";

function hasHtmlTag(text: string) {
	const pattern = /<.*?>/g;
	return pattern.test(text);
}

export function validateSearchInput(value: string): string {
	const badStrings = ["�", "st4r7s<", "<script>alert", "dfb", "alert("];
	const bannedStrings = ["the"];

	// shouldn't have bad string
	if (
		badStrings.some((bad) => value.includes(bad)) ||
		hasHtmlTag(value) ||
		bannedStrings.some((bannedText) => value === bannedText)
	) {
		return "Invalid search query";
	}

	// shouldn't contain emoji
	const emojiRegex =
		/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/;
	if (emojiRegex.test(value)) {
		return "Emojis are not allowed";
	}

	// shouldn't exceed max-length
	const maxLength = 60;
	if (value.length > maxLength) {
		return `Please limit your search query to ${maxLength} characters or fewer.`;
	}

	return "";
}

export function SearchInput(props: SearchBoxProps) {
	const { query, refine } = useSearchBox(props);
	const [honeyPot, setHoneyPot] = React.useState("");
	const [error, setError] = React.useState("");

	const inputRef = React.useRef<HTMLInputElement>(null);

	React.useEffect(() => {
		if (!query) {
			inputRef.current?.focus({ preventScroll: true });
		}
	}, [query]);

	const debouncedSearch = debounce((value: string) => {
		// const sanitizedValue = sanitizeInput(value);

		// do nothing if the honeypot is filled
		if (honeyPot) {
			console.info(`FAILED HONEYPOT WHEN SEARCHING: `, query);
			return;
		}

		spTrackSearchTerm(value);

		refine(value);
	}, 500);

	function onSearchChange(event: React.FormEvent<HTMLInputElement>) {
		const inputTarget = event.currentTarget;
		const value = inputTarget.value;
		const errorMessage = validateSearchInput(value);

		if (errorMessage) {
			setError(errorMessage);
		} else {
			setError("");
			debouncedSearch(value);
		}
	}

	function onHoneyPotChange(event: React.FormEvent<HTMLInputElement>) {
		const inputTarget = event.currentTarget;
		const value = inputTarget.value;

		setHoneyPot(value);
	}

	return (
		<div>
			<div className="relative">
				<Icon
					name="search"
					color="secondary"
					className="absolute left-5 top-1/2 translate-y-[-50%]"
					width="16"
					height="16"
				/>
				<Input
					ref={inputRef}
					type="search"
					id="search"
					name="search"
					defaultValue={query}
					placeholder={"Search"}
					onChange={onSearchChange}
					className="!p-5 !pl-[56px]"
					aria-invalid={Boolean(error)}
					aria-describedby="search-error"
				/>

				<HoneyPotInput
					value={honeyPot}
					name="language__option"
					onChange={onHoneyPotChange}
				/>
			</div>
			{error ? (
				<InputError id="search-error" className="mt-3">
					{error}
				</InputError>
			) : null}
		</div>
	);
}
