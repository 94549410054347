import type { Hit as HitType } from "instantsearch.js";
import {
	Highlight as AlgoliaHighlight,
	Snippet,
	type HighlightProps,
} from "react-instantsearch";
import { Card } from "~/components/ui/card";
import { Paragraph } from "~/components/ui/typography";
import { asText, asLink } from "~/utils/sanity-helpers";
import { getFormattedDateTime } from "../sections/event-list";
import { useSharedContent } from "~/hooks/localization";
import { Icon } from "~/components/ui/icons";
import { formatDate, isExternalUrl } from "~/utils/misc";
import { type SearchPreview } from "~/types/global-search";
import { getCountryName } from "~/route-containers/events/country-helper";

const labelForType: Record<SearchPreview["_type"], string> = {
	post: "Blog",
	caseStudy: "Case study",
	devArticle: "Guide",
	event: "Event",
	whitepaper: "White paper",
	ebook: "E-book",
	report: "Report",
	webinar: "Webinar",
	person: "People",
	pressRelease: "Press release",
	pressNews: "News",
	product: "Service",
	solution: "Solution",
	timeSeries: "Time series database",
	term: "Term & Policy",
	page: "Page",
	documentation: "Documentation",
};

type HitProps = {
	hit: HitType<SearchPreview>;
};

function Highlight(props: HighlightProps<HitProps["hit"]>) {
	const { key, ...rest } = props;

	return (
		<AlgoliaHighlight
			classNames={{
				highlighted: "bg-secondary-5  text-secondary-100",
			}}
			{...rest}
			key={key as string}
		/>
	);
}

export function Hit({ hit }: HitProps) {
	const { t } = useSharedContent();

	if (hit._type === "event") {
		const dateTime = getFormattedDateTime(hit);
		const country = hit.country ? getCountryName(hit.country) : "";
		const location = country ? `${hit.location}, ${country}` : hit.location;

		const cta = {
			title: hit.primaryCTA?.title,
			url: hit.contentIsPage && hit.slug ? hit.slug : asLink(hit.primaryCTA),
		};

		return (
			<Card href={cta?.url ?? ""} trackingPosition="search">
				<Card.Content className="!px-5">
					<Icon
						className="absolute right-6 top-6"
						name="external-link"
						height="12px"
						width="14px"
						color="primary"
					/>
					<Card.ChipText>{t(asText(hit.type))}</Card.ChipText>
					<Card.Title>
						<Highlight attribute="title" hit={hit} />
					</Card.Title>
					<Paragraph className="mb-4" color="secondary">
						<Highlight attribute="subtitle" hit={hit} />
					</Paragraph>
					{dateTime ? (
						<>
							<div className="mb-3 flex items-center gap-3">
								<Icon name="calendar" color="primary" />
								<Paragraph size="body-small">
									<span suppressHydrationWarning>{dateTime.date}</span>
								</Paragraph>
							</div>
							<div className="mb-3 flex items-center gap-3">
								<Icon name="clock" color="primary" />
								<Paragraph size="body-small">
									<span suppressHydrationWarning>{dateTime.time}</span>
								</Paragraph>
							</div>
						</>
					) : null}

					{location ? (
						<div className="mb-3 flex items-center gap-3">
							<Icon name="location" color="primary" />
							<Paragraph size="body-small">{location}</Paragraph>
						</div>
					) : null}
				</Card.Content>
			</Card>
		);
	}

	const slug =
		hit._type == "person" ? `/community/devrel${hit.slug}` : `${hit.slug}`;

	const showLastUpdated =
		hit._type === "devArticle" ||
		hit._type === "post" ||
		hit._type === "pressNews" ||
		hit._type === "term" ||
		hit._type === "pressRelease";

	const showSnippet = ["documentation", "caseStudy", "term"].includes(
		hit._type
	);

	return (
		<Card href={slug}>
			<Card.Content>
				{showLastUpdated && hit.publishedAt ? (
					<Paragraph size="body-small" color="secondary" className="mb-6">
						<span
							suppressHydrationWarning
						>{`Last updated ${formatDate(hit.publishedAt)}`}</span>
					</Paragraph>
				) : null}
				{isExternalUrl(slug) ? (
					<Icon
						className="absolute right-6 top-6"
						name="external-link"
						height="12px"
						width="14px"
						color="primary"
					/>
				) : null}
				<div className="flex gap-3">
					<Card.ChipText>{labelForType[hit._type]}</Card.ChipText>
					{hit._type === "product" && hit.version ? (
						<Card.ChipText
							bgColor="bg-theme-secondary"
							textColor="theme-secondary"
						>
							Now running {hit.version}
						</Card.ChipText>
					) : null}
				</div>
				<Card.Title>
					<Highlight attribute="title" hit={hit} />
				</Card.Title>

				{hit._type === "person" ? (
					<Paragraph fontWeight="font-semibold" className="mb-3">
						{hit.jobTitle}
					</Paragraph>
				) : null}

				<Paragraph color="secondary" whiteSpacePreLine={false}>
					{showSnippet ? (
						<Snippet
							classNames={{
								highlighted: "bg-secondary-5  text-secondary-100",
							}}
							hit={hit}
							attribute={["body"]}
						/>
					) : (
						<Highlight hit={hit} attribute="subtitle" />
					)}
				</Paragraph>
			</Card.Content>
		</Card>
	);
}
